/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
@use '@angular/material' as mat;
@use '@material/switch/switch' as mdc-switch;
@use '@material/switch/switch-theme' as mdc-switch-theme;

//@import '@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');

$fontFamily: 'Nunito Sans', sans-serif;

$fontConfig: mat.define-typography-config(
  $font-family: $fontFamily,
  $headline-1: mat.define-typography-level(32px, 48px, 500, 'Nunito Sans', 0em),
  $headline-2: mat.define-typography-level(32px, 48px, 600, 'Nunito Sans', 0em),
  $headline-3: mat.define-typography-level(32px, 72px, 700, 'Nunito Sans', 0em),
  $headline-4: mat.define-typography-level(32px, 32px, 500, 'Nunito Sans', -0.0134em),
  $headline-5: mat.define-typography-level(24px, 32px, 500, 'Nunito Sans', 0em),
  $headline-6: mat.define-typography-level(20px, 32px, 500, 'Nunito Sans', 0em),
  $subtitle-2: mat.define-typography-level(14px, 20px, 700, 'Nunito Sans', 0.18px),
  $subtitle-1: mat.define-typography-level(16px, 24px, 700, 'Nunito Sans', 0.18px),
  $body-1: mat.define-typography-level(16px, 24px, 500, 'Nunito Sans', 0.18px),
  $body-2: mat.define-typography-level(14px, 20px, 500, 'Nunito Sans', 0.18px),
  $button: mat.define-typography-level(14px, 14px, 600, 'Nunito Sans', 1.5px),
  $caption: mat.define-typography-level(12px, 16px, 500, 'Nunito Sans', 0.18px),
  $overline: mat.define-typography-level(12px, 16px, 500, 'Nunito Sans', 0.18px),
);

$fontConfigLegacy: mat.define-legacy-typography-config(
  $font-family: $fontFamily,
  $display-1: mat.define-typography-level(32px, 48px, 500, 'Nunito Sans', 0em),
  $display-2: mat.define-typography-level(32px, 48px, 600, 'Nunito Sans', 0em),
  $display-3: mat.define-typography-level(32px, 32px, 700, 'Nunito Sans', 0em),
  $display-4: mat.define-typography-level(32px, 32px, 500, 'Nunito Sans', -0.0134em),
  $headline: mat.define-typography-level(24px, 32px, 500, 'Nunito Sans', 0em),
  $title: mat.define-typography-level(20px, 32px, 700, 'Nunito Sans', 0.0075em),
  $subheading-2: mat.define-typography-level(14px, 20px, 700, 'Nunito Sans', 0.18px),
  $subheading-1: mat.define-typography-level(16px, 24px, 700, 'Nunito Sans', 0.18px),
  $body-2: mat.define-typography-level(14px, 20px, 500, 'Nunito Sans', 0.18px),
  $body-1: mat.define-typography-level(16px, 24px, 500, 'Nunito Sans', 0.18px),
  $button: mat.define-typography-level(14px, 14px, 600, 'Nunito Sans', 1.5px),
  $caption: mat.define-typography-level(12px, 16px, 500, 'Nunito Sans', 0.18px),
  $input: mat.define-typography-level(inherit, 1.125, 500, 'Nunito Sans', 0.18px),
);

// Foreground Elements

// Light Theme Text
$fig-dark-text: #000000;
$fig-dark-primary-text: rgba($fig-dark-text, 0.84);
$fig-dark-accent-text: rgba($fig-dark-text, 0.6);
$fig-dark-disabled-text: rgba($fig-dark-text, 0.3);
//$dark-dividers: rgba($dark-text, 0.04);
//$dark-focused: rgba($dark-text, 0.12);

// Dark Theme text
$fig-light-text: #ffffff;
$fig-light-primary-text: $fig-light-text;
//$light-accent-text: rgba($light-primary-text, 0.7);
//$light-disabled-text: rgba($light-primary-text, 0.5);
//$light-dividers: rgba($light-primary-text, 0.12);
//$light-focused: rgba($light-primary-text, 0.12);

// Background config
// Light bg
$fig-light-background: #f5f5f5;
//$light-bg-darker-5: darken($light-background, 5%);
//$light-bg-darker-10: darken($light-background, 10%);
//$light-bg-darker-20: darken($light-background, 20%);
//$light-bg-darker-30: darken($light-background, 30%);
//$light-bg-lighter-5: lighten($light-background, 5%);
//$dark-bg-tooltip: lighten(#2c2c2c, 20%);
//$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
//$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

// Dark bg
//$dark-background: #000000;
//$dark-bg-lighter-5: lighten($dark-background, 5%);
//$dark-bg-lighter-10: lighten($dark-background, 10%);
//$dark-bg-lighter-20: lighten($dark-background, 20%);
//$dark-bg-lighter-30: rgba($dark-background, 0.3);
//$light-bg-alpha-4: rgba(#f5f5f5, 0.04);
//$light-bg-alpha-12: rgba(#f5f5f5, 0.12);

// Figma colour variables
body {
  --primary: #3a3f65;
  --accent-coral: #ed705e;
  --accent: #2f5794;
  --neutral: #e9d7c8;
  --white: #{$fig-light-text};
  --grey-lightest: #{$fig-light-background};
  --grey-lighter: #{rgba($fig-dark-text, 0.09)};
  --grey-light: #{rgba($fig-dark-text, 0.19)};
  --grey-dark: #{$fig-dark-disabled-text};
  --grey-darker: #{$fig-dark-accent-text};
  --grey-darkest: #292929;
  --black: #{$fig-dark-text};
  --error: #ba2a15;
  --warning: #f7b801;
  --success: #306b34;
  --grey-text: #666666;
  --dark-light-text: #292929;
  --light-gray-text: #b3b3b3;
  --lightest-gray-text: #d6d6d6;
  --border-lightest: #e9e9e9;
  --overlay-bg: #00000080;
  --color__orange: #f3722c;
  --color__yellow: var(--warning);
  --color__red: var(--error);
  --color__deal-status--eoi: var(--warning);
  --color__deal-status--issued: var(--warning);
  --color__deal-status--conditional: var(--color__orange);
  --color__deal-status--unconditional: var(--color__red);
  --color__deal-status--settled: var(--primary);
  --color__listing-status--developer-sale: var(--color__red);
  --color__listing-status--withheld: var(--color__red);
  // custom vars set vis CssService
  --apartment-plan-editor-bg: '';
  --mdc-filled-button-container-color: var(--primary);
  --mdc-filled-button-label-text-color: var(--white);
  // mat-spinner
  --mdc-circular-progress-active-indicator-color: var(--primary);
}

$mat-primary: (
  main: #3a3f65,
  lighter: #e3e5ef,
  darker: #1d2033,
  200: #3a3f65,
  // For slide toggle,
  contrast:
    (
      main: $fig-light-primary-text,
      lighter: $fig-light-primary-text,
      darker: $fig-light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: #3a3f65,
  lighter: #deedff,
  darker: #74acff,
  200: var(--primary-lighter-color),
  // For slide toggle,
  contrast:
    (
      main: $fig-light-primary-text,
      lighter: $fig-light-primary-text,
      darker: $fig-light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: #ba2a15,
  lighter: rgba(251, 78, 78, 0.1),
  darker: rgba(251, 78, 78, 0.5),
  200: rgba(251, 78, 78, 0.5),
  // For slide toggle,
  contrast:
    (
      main: $fig-light-primary-text,
      lighter: $fig-light-primary-text,
      darker: $fig-light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
    density: 0,
  )
);

$legacyTheme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfigLegacy,
    density: 0,
  )
);

// Theme Init
//@include mat.all-legacy-component-themes($theme);
//@include mat.all-legacy-component-typographies($fontConfigLegacy);

//@include mat.all-component-themes($theme);
//@include mat.all-component-typographies($fontConfig);
// Specific component overrides, pieces that are not in line with the general theming
@include mat.legacy-core();
@include mat.core();
@include mat.all-legacy-component-themes($legacyTheme);
@include mat.all-legacy-component-typographies($fontConfig);
@include mat.all-component-themes($theme);
@include mat.all-component-typographies($fontConfig);
// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button,
.mcd-button,
.mat-mdc-button-base,
.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-mdc-outlined-button,
.mat-stroked-button:not([disabled]) {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  box-shadow: none;
  border: 1px solid var(--primary-color);

  &:hover {
    text-decoration: none;
  }

  &.icon-right {
    padding-right: 8px;
  }

  &.icon-left {
    padding-left: 8px;
  }
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

$mat-tab-animation-duration: 5000ms;

.material-icons {
  font-size: 24px;
  //noinspection CssNoGenericFontName
  font-family: 'Material Icons';

  .mat-badge-content {
    font-family: 'Nunito Sans', sans-serif;
  }
}

// Theme Config
body.skychute {
  --primary-color: #3a3f65;
  --primary-lighter-color: #e3e5ef;
  --primary-darker-color: #1d2033;
  --text-primary-color: #{$fig-dark-primary-text};
  --text-primary-lighter-color: #{$fig-light-primary-text};
  --text-primary-darker-color: #{$fig-dark-text};
  --accent-color: #2f579f;
  --accent-lighter-color: #b6d4ff;
  --accent-darker-color: #1d2033;
  --text-accent-color: #2f579f;
  --text-accent-lighter-color: #{$fig-light-primary-text};
  --text-accent-darker-color: #{$fig-light-primary-text};
  --warn-color: #ba2a15;
  --warn-lighter-color: rgba(251, 78, 78, 0.1);
  --warn-darker-color: rgba(251, 78, 78, 0.5);
  --text-warn-color: #{$fig-light-primary-text};
  --text-warn-lighter-color: #{$fig-light-primary-text};
  --text-warn-darker-color: #{$fig-light-primary-text};
}

//
// New Material MDC Style
//
body.skychute {
  .mat-mdc-tab,
  .mat-mdc-tab span {
    font-family: $fontFamily;
    letter-spacing: 0.18px;
  }

  .mat-ligature-font {
    font-family: 'Material Icons', sans-serif !important;
  }

  .mdc-button .mdc-button__label {
    text-transform: uppercase;
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    --mdc-dialog-supporting-text-color: #{$fig-dark-primary-text};
  }

  // slightly grey unchecked checkbox mdc
  .mat-mdc-checkbox .mdc-checkbox__background {
    --mdc-checkbox-unselected-icon-color: var(--grey-light) !important;
    --mdc-checkbox-unselected-hover-icon-color: var(--grey-light) !important;
    --mdc-checkbox-unselected-pressed-icon-color: var(--grey-light) !important;
    --mdc-checkbox-unselected-focus-icon-color: var(--grey-light) !important;
  }
  .mdc-tooltip__surface {
    background: var(--primary) !important;
    max-width: 300px !important;
  }
}

$_color-selected-handle: var(--primary);
$_color-unselected-handle: var(--white);

$_color-unselected-track: var(--grey-dark);
$_color-selected-track: var(--primary);
$_color-disabled-selected-track: $_color-selected-track;
$_color-disabled-unselected-track: $_color-unselected-track;

.mat-mdc-slide-toggle {
  .mdc-switch {
    @include mdc-switch-theme.theme(
      (
        //handle color
        disabled-selected-handle-color: $_color-selected-handle,
        disabled-unselected-handle-color: $_color-unselected-handle,
        selected-handle-color: $_color-selected-handle,
        selected-focus-handle-color: $_color-selected-handle,
        selected-hover-handle-color: $_color-selected-handle,
        selected-pressed-handle-color: $_color-selected-handle,
        unselected-handle-color: $_color-unselected-handle,
        unselected-focus-handle-color: $_color-unselected-handle,
        unselected-hover-handle-color: $_color-unselected-handle,
        unselected-pressed-handle-color: $_color-unselected-handle,
        //tracks color
        disabled-selected-track-color: $_color-selected-track,
        disabled-unselected-track-color: $_color-unselected-track,
        selected-track-color: $_color-selected-track,
        selected-focus-track-color: $_color-selected-track,
        selected-hover-track-color: $_color-selected-track,
        selected-pressed-track-color: $_color-selected-track,
        unselected-track-color: $_color-unselected-track,
        unselected-focus-track-color: $_color-unselected-track,
        unselected-hover-track-color: $_color-unselected-track,
        unselected-pressed-track-color: $_color-unselected-track,
        // icon colors
        disabled-selected-icon-color: $_color-selected-handle,
        disabled-unselected-icon-color: $_color-unselected-handle,
        selected-icon-color: $_color-selected-handle,
        unselected-icon-color: $_color-unselected-handle
      )
    );
  }
}

.mdc-menu-surface.mat-mdc-select-panel {
  min-width: 150px;
}

.mat-select-search-clear {
  padding: 8px 6px !important;
  min-width: 40px;

  &:hover {
    .mdc-icon-button__ripple {
      &::before {
        background-color: white !important;
      }
    }
  }
}
