/* !!! this must be the first rule, don't move it anywhere else or printing feature will break */
@page {
  size: A3 landscape;
}

@import './theme.scss';
@import './breakpoints';
/**
This is temp solution until issue does not resolve at package/lib side
https://github.com/h2qutc/angular-material-components/issues/357
./date-picker scss will be removed once the issue is fixed at library
 */
@import './date-picker';

$font-family: 'Nunito Sans';

html,
body {
  height: 100%;

  &.skychute {
    background-color: white;
  }

  &.cdk-overlay-container,
  &.skychute {
    // for the scrollbar
    :not(pre)::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track:vertical {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-button:vertical {
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    /* Turn on single button up on top, and down on bottom */

    ::-webkit-scrollbar-button:start:decrement,
    ::-webkit-scrollbar-button:end:increment {
      display: block;
    }

    /* Turn off the down area up on top, and up area on bottom */

    ::-webkit-scrollbar-button:vertical:start:increment,
    ::-webkit-scrollbar-button:vertical:end:decrement {
      display: none;
    }

    /* Place The scroll down button at the bottom */

    ::-webkit-scrollbar-button:vertical:increment {
      display: none;
    }

    /* Place The scroll up button at the up */

    ::-webkit-scrollbar-button:vertical:decrement {
      display: none;
    }

    ::-webkit-scrollbar-track:vertical {
      background-color: transparent;
      border-radius: 5px;
    }

    /* Track below and above */

    ::-webkit-scrollbar-track-piece:vertical {
      background-color: transparent;
    }

    /* Top area above thumb and below up button */

    ::-webkit-scrollbar-track-piece:vertical:start {
      border-radius: 5px;
    }

    /* Bottom area below thumb and down button */

    ::-webkit-scrollbar-track-piece:vertical:end {
      border-radius: 5px;
    }

    /* The thumb itself */

    ::-webkit-scrollbar-thumb:vertical {
      width: 8px;
      height: 10px;
      background: linear-gradient(45deg, var(--accent-coral), var(--primary));
      border-radius: 10px;
    }

    /* Corner */
    ::-webkit-scrollbar-corner:vertical {
      background-color: var(--accent-coral);
    }

    ::-webkit-scrollbar-thumb:horizontal {
      width: 8px;
      height: 10px;
      background: linear-gradient(45deg, var(--accent-coral), var(--primary));
      border-radius: 10px;
    }

    /* Corner */
    ::-webkit-scrollbar-corner:horizontal {
      background-color: var(--accent-coral);
    }
  }
}

body {
  &.skychute {
    margin: 0;
    font-family: $font-family;
    letter-spacing: 0.18px;

    p {
      color: var(--grey-darkest);
    }

    a {
      color: var(--accent);
      text-decoration: none;
    }
  }
}

skychute-pages,
skychute-embed,
.buyer-sign-up,
skychute-root,
.cdk-overlay-container {
  .mat-select-trigger {
    min-height: 1.125em;
    height: unset;
  }

  a {
    color: var(--accent);
    text-decoration: none;
  }

  .headline-4,
  h1.headline-4,
  .mat-typography h1.headline-4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  .mat-title {
    &.underline {
      padding-bottom: 4px;
      border-bottom: 1px solid var(--grey-lighter);
      width: 100%;
    }
  }

  .mat-overline {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    margin-bottom: 8px;
  }

  .mat-button-text {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 36.4px;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-bottom: 0;
  }

  .no-padding-dialog {
    padding: 16px 0;

    @include layout-bp('lt-md') {
      padding: 0;
    }

    .mat-dialog-container {
      padding: 0;
      height: unset;

      @include layout-bp('lt-md') {
        border-radius: 0;
      }
    }
  }

  .search-menu-dialog {
    padding: 0;

    @include layout-bp('lt-md') {
      padding: 0;
    }

    .mat-dialog-container {
      padding: 0;
      height: unset;

      @include layout-bp('lt-md') {
        border-radius: 0;
      }
    }
  }

  .wizard-header {
    background-color: var(--grey-lightest);
  }

  .mat-step-header .mat-step-icon {
    width: 20px;
    height: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  .mat-step-label-selected {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }

  .mat-step-header .mat-step-label {
    color: var(--grey-dark);
  }

  .mat-step-header .mat-step-icon {
    background: var(--grey-dark);
  }

  .mat-step-header .mat-step-icon-selected {
    background-color: var(--primary);
  }

  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    /* Accent blue */
    background: var(--success);
  }

  .mat-icon.mat-primary {
    color: var(--primary);
  }

  .mat-icon-button {
    &.mat-primary {
      color: var(--primary);
    }
  }

  .mat-stroked-button {
    &.mat-primary,
    &:not([disabled]) {
      color: var(--primary);
      border-color: var(--primary);
      background-color: var(--white);
    }
  }

  .mat-progress-bar-buffer {
    background: var(--primary-lighter-color);
  }

  .mat-progress-bar-fill::after {
    background-color: var(--primary);
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--accent);
  }

  .mat-checkbox-layout .mat-checkbox-label {
    line-height: inherit !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--accent);
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--accent);
  }

  .cdk-overlay-dark-backdrop {
    background: var(--grey-darkest);
    opacity: 0.5;
  }

  .cdk-overlay-backdrop.price-list-dialog.cdk-overlay-backdrop-showing {
    background: none;
  }

  .cdk-overlay-backdrop.price-list-menu.cdk-overlay-backdrop-showing {
    background: none;
  }

  .cdk-overlay-backdrop.contract-status-filter-menu-dropdown.cdk-overlay-backdrop-showing {
    background: none;
  }

  .cdk-overlay-backdrop.-menu.cdk-overlay-backdrop-showing {
    background: none;
  }

  .mat-form-field-infix {
    //width: 100% !important;
  }

  .custom-wizard {
    height: 100%;
    overflow: hidden;

    .custom-wizard-content {
      flex: 1 1 auto;
      height: 100%;
    }

    .mat-horizontal-stepper-content,
    .mat-vertical-stepper-content {
      height: 100%;
    }

    .mat-stepper-vertical-line {
      height: 100%;
    }

    .mat-horizontal-content-container,
    .mat-vertical-content-container {
      flex: 1 1 auto;
      height: 100%;
    }

    .mat-stepper-horizontal,
    .mat-stepper-vertical {
      height: 100%;
      display: flex !important;
      flex-direction: column;

      @include layout-bp('lt-md') {
        :nth-child(3) {
          flex: 1 1 auto;
          height: 100%;
        }
      }
    }

    .mat-horizontal-stepper-content,
    .mat-vertical-stepper-content {
      display: flex;
      flex-direction: column;
    }

    .mat-horizontal-stepper-content[aria-expanded='false'],
    .mat-vertical-stepper-content[aria-expanded='false'] {
      flex: 0 0 auto;
    }

    .mat-horizontal-stepper-content[aria-expanded='true'],
    .mat-vertical-stepper-content[aria-expanded='true'] {
      flex: 1 1 auto;
    }

    .mat-dialog-content {
      flex: 1 1 auto !important;
      margin: 0;
      padding: 0;
      //max-height: 100%;
      height: 100%;
    }

    .mat-stepper-vertical {
      height: 100%;
      display: flex !important;
      flex-direction: column;

      @include layout-bp('xs') {
        :nth-child(3) {
          flex: 1 1 auto;
        }
      }
    }
  }

  .position__relative {
    position: relative;
  }

  .mat-select-panel {
    input:focus {
      outline: none;
    }

    input {
      width: 100%;
      border: 0;
      height: 36px;
    }
  }

  .select-box {
    .mat-select-placeholder {
      //color: var(--grey-darker);
    }
  }

  .client-info-modal-container .mat-tab-labels {
    display: flex;
    flex-direction: row;

    @include layout-bp('gt-sm') {
      padding-left: 117px;
      padding-right: 117px;
    }

    @include layout-bp('sm') {
      padding-left: 91px;
      padding-right: 91px;
    }

    .mat-tab-label {
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      flex: 50%;
    }
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: var(--primary);
  }

  input[readonly] {
    color: rgba(0, 0, 0, 0.38);
  }

  label span.mat-form-field-required-marker {
    color: var(--error);
  }

  .mat-form-field-prefix .mat-icon,
  .mat-form-field-suffix .mat-icon {
    color: var(--grey-light);
  }

  .custom-table {
    table,
    td,
    th {
      border: 1px solid var(--grey-lighter);
      padding: 8px;
    }

    table {
      border-collapse: collapse;

      @include layout-bp('xs') {
        width: 100%;
      }

      @include layout-bp('gt-xs') {
        width: 326px;
      }
    }

    th {
      padding: 12px 16px;
      font-size: 12px;
      line-height: 16px;
      //color: var(--grey-darker);
      //background: var(--grey-lightest) !important;
      opacity: 0.5;
      text-align: left;
    }

    td {
      padding: 16px;
      font-size: 14px;
      line-height: 19px;

      /* Title texts, captions, input fields and everywhere else where black is required */
      color: var(--grey-darkest);
    }
  }

  .dialog-container-content {
    @include layout-bp('gt-sm') {
      max-width: 442px;
      min-width: 442px;
    }

    @include layout-bp('sm') {
      max-width: 522px;
      min-width: 522px;
    }

    @include layout-bp('xs') {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: var(--primary-color);
  }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    mat-progress-bar {
      .mat-progress-bar-buffer {
        background-color: var(--grey-light) !important;
      }
    }
  }

  .word-break {
    word-break: break-all;
  }

  .tab-label {
    padding: 5px;
    background-color: transparent;
    color: var(--primary);
    border-radius: 4px 4px 0 0;
    width: 225px;
    border: 1px solid var(--grey-light);
    box-sizing: border-box;

    @include layout-bp('lt-md') {
      max-width: fit-content;
    }
  }

  .tab-label-content {
    display: flex;
    flex-direction: row;

    @include layout-bp('lt-sm') {
      flex-direction: column;
    }
  }

  .floor_action {
    .mat-select-value {
      max-width: none;
    }
  }

  .price-list-mat-cell {
    margin-right: 30px;
  }

  .price-list-no-padding-dialog .mat-dialog-container {
    padding: 0 !important;
    overflow-y: hidden;
  }

  .area-plot-renderer-no-radius-dialog .mat-dialog-container {
    display: block;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    padding: 0px !important;
    border-radius: 0px;
    box-shadow: none;
  }

  .deposit-info-no-radius-dialog .mat-dialog-container {
    display: block;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    padding: 16px !important;
    border-radius: 0px;
    box-shadow: none !important;
  }

  snack-bar-container.success {
    background-color: var(--success);
  }

  snack-bar-container.warning {
    background-color: var(--warning);
  }

  snack-bar-container.error {
    background-color: var(--error);
  }

  snack-bar-container.notification {
    font-size: 18px !important;
    color: var(--white);
    font-weight: 700;
  }

  .filters-dialog {
    .mat-expansion-panel-body {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 24px;
    }
  }

  .land-plans-editor {
    .mat-expansion-panel-body {
      padding: 0 0 16px 0;
    }

    .mat-expansion-panel-header-title {
      align-items: center;
      margin-right: 0;
    }

    .mat-expansion-panel-header {
      padding: 0 0 0 16px;
    }

    .mat-icon-button {
      line-height: inherit;
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    .mat-accordion .mat-expansion-panel:first-of-type {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .mat-accordion .mat-expansion-panel:last-of-type {
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
    }

    .mat-expansion-panel-header[aria-disabled='true'] {
      color: unset;
    }
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: var(--white);
  }

  #choose-buyer.mat-form-field {
    width: 100%;
  }

  .filter-dropdown {
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.18px;

    /* Secondary text */
    color: var(--grey-darker);

    .mat-select-placeholder {
      color: var(--grey-darker);
    }
  }

  .readonly {
    pointer-events: none;
  }

  .dialog-no-top-shadow .mat-dialog-container {
    box-shadow: 0 12px 17px rgba(0, 0, 0, 0.14), 0 5px 22px rgba(0, 0, 0, 0.12),
    0 7px 8px rgba(0, 0, 0, 0.2);
    padding: 0;
    margin: 0;
    border-radius: 0;
  }

  //
  // Figma styles
  //

  .fig-headline-2 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
  }

  .fig-headline-3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
  }

  .fig-headline-4 {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .fig-headline-5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .fig-headline-6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .fig-subtitle-1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .fig-body-1 {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .fig-body-2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .fig-overline {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  .fig-caption {
    font-size: 12px;
    line-height: 16px;
  }

  .buyer-email-preview {
    max-width: 100vw !important;
    overflow: auto;

    .mat-dialog-container {
      padding: 0;
    }
  }

  //
  // Corinne's feedback
  // https://skychute.atlassian.net/wiki/spaces/CP/pages/1613561905/Buyer+Portal+Feedback
  //

  // field label
  .mat-form-field-label {
    letter-spacing: 0;
  }

  // field below field with error should move down 16px
  .mat-form-field.mat-form-field-invalid + .mat-form-field {
    margin-top: 16px;
  }

  // assistive text / error
  .mat-error {
    letter-spacing: 0;
    font-size: 12px;
    font-weight: normal;
  }

  // Buyer portal
  .buyer-portal__buttons {
    // TODO will be removed
    display: flex;
    justify-content: space-between;
    width: calc(100% - 360px);
    position: fixed;
    left: 360px;
    bottom: 0;
    padding: 8px;
    background: rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    z-index: 100;

    @include layout-bp('lt-md') {
      left: 0;
      width: 100%;
      padding: 8px 24px;
    }

    .mat-stroked-button,
    .mat-flat-button {
      margin: 0;
    }
  }

  .buyer-portal__click-floorplan {
    @extend .fig-overline;
    text-transform: uppercase;
    color: #a8a8a8;
    margin: 8px 0 40px;
  }

  .buyer-portal__tablet-nav-item {
    background: var(--grey-lightest);
    padding: 6px 24px;
    position: fixed;
    left: 0;
    top: 55px;
    width: calc(100% - 48px);
    z-index: 2;

    @include layout-bp('gt-sm') {
      display: none;
    }
  }

  // Buyer Portal: floor plan dialog
  .empty-full-width-dialog {
    .mat-dialog-container {
      border-radius: 0;
      padding: 0;
    }
  }

  //Buyer Portal, Reservation Wizard & Client Registration
  //Payment method styling
  .stripe {
    margin-top: 16px;

    .mat-form-field-appearance-standard .mat-form-field-underline {
      background-color: rgba(0, 0, 0, 0.18);
    }

    .mat-form-field-appearance-standard .mat-form-field-flex {
      background: var(--white);
      padding: 8px 0 4px 0;
      border-radius: 4px 4px 0 0;
    }

    .mat-form-field-infix {
      text-indent: 8px;
    }

    .field {
      background: var(--white);
      border-radius: 4px 4px 0 0;
    }
  }

  .reservation-buttons button,
  .reservation-buttons .mat-stroked-button {
    @include layout-bp('lt-sm') {
      font-size: 12px;
      padding: 0 8px;
    }
  }

  #deal-holding-deposit-payment__method-tab {
    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
      background-color: transparent;
    }

    &.deal-holding-deposit-payment__method-tab .mat-tab-labels {
      display: flex;
      flex-direction: row;

      .mat-tab-label {
        border: 1px solid var(--grey-lightest);
        box-sizing: border-box;
        border-radius: 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        opacity: 1;
        background: var(--white);
        min-width: 130px;
        height: 54px;

        .mat-tab-label-content {
          color: var(--primary-color);
        }
      }

      .mat-tab-label-active {
        border-top: 2px solid var(--accent-coral);
        background: var(--grey-lightest);
        border-bottom: 1px solid var(--grey-lightest);
      }
    }
  }

  //Tabs on all lists - e.g. Apartments, Land, Townhouses, Houses & Land.
  .mat-tab-group.price-allocation-stock-list__tabs.mat-primary,
  .price-listing__tabs.mat-primary,
  .mat-tab-group.partners-list__tabs.mat-primary {
    .mat-ink-bar {
      background-color: transparent;
    }

    .mat-mdc-tab-labels,
    .mat-tab-label {
      opacity: 1;
      letter-spacing: 0.18px;
      font-weight: 400;
      height: 40px;

      @include layout-bp('lt-sm') {
        width: auto;
      }
    }

    .mdc-tab {
      border-radius: 4px 4px 0 0;
      background-color: var(--grey-lightest);
    }

    .mdc-tab__content,
    .mat-mdc-tab-header {
      height: 40px;
    }

    .mdc-tab-indicator--active,
    .mat-tab-label-active {
      background-color: var(--primary);
      color: var(--white);
      font-weight: 700;

      .mdc-tab__text-label {
        color: var(--white);
      }

      .price-allocation-stock-list__tab-icon,
      .price-listing__tab-icon,
      .partner-list__tab-icon {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(179deg) brightness(200%) contrast(113%);
      }
    }
  }

  //Team settings - Role select filter and set buttons
  button.user-list__role-filter,
  button.user-list__set-role {
    span.mat-button-wrapper {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  //Team settings - Invitation Dialog (failed to send text)
  .team-setting-dialog__list-item .mat-list-item-content {
    justify-content: center;
    gap: 6px;
  }

  .team-setting-dialog__form-row {
    .mat-form-field.mat-form-field-invalid + .mat-form-field {
      margin-top: 0;
    }
  }

  //Merged Price List - Buttons
  .buttons__allocate-edit-download {
    #download-button .mat-button-wrapper .mat-icon,
    #edit-button .mat-button-wrapper .mat-icon {
      font-size: 16px;
      height: 16px;
      width: 16px;
      padding-right: 8px;
    }
  }

  //Merged Price List - Slider Filters
  mat-dialog-container#listing-filters-dialog {
    padding: 0;
    border-radius: 0;
  }

  //Merged price list table overrides
  .price-allocation-stock__table {
    th.mat-header-cell.price,
    td.mat-cell.price,
    td.mat-footer-cell.price,
    th.mat-header-cell.lot-measure,
    td.mat-cell.lot-measure,
    td.mat-footer-lot-measure,
    th.mat-header-cell.lot-spec,
    td.mat-cell.lot-spec,
    td.mat-footer-lot-spec {
      .mat-sort-header-container {
        justify-content: flex-end;
      }
    }

    th.mat-header-cell:last-of-type {
      padding-right: 0;
    }
  }

  //Links
  .global__back-option,
  .link__icon-right,
  .link__icon-left {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    gap: 8px;
    color: var(--accent);
    text-decoration: none;
    cursor: pointer;

    .global__back-text,
    .link-text {
      text-transform: uppercase;
      cursor: pointer;
      letter-spacing: 1.5px;
      font-weight: 700;
      font-size: 14px;
      line-height: 36.4px;
      text-decoration: none;
      font-family: Nunito Sans, sans-serif;

      &:hover {
        text-decoration: underline;
      }
    }

    .mat-icon.material-icons {
      width: 18px;
      font-size: 18px;
      line-height: 35px;
      color: var(--accent);
      text-decoration: none;
    }

    .icon-left {
      fill: var(--accent);
      max-width: 100%;
      width: 18px;
      padding-top: 8px;
    }
  }

  .clear-filter-link,
  .clear-filter-link-disabled,
  .disable-clear-filter,
  .clear-filter {
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    font-family: Nunito Sans, sans-serif;

    &:hover {
      text-decoration: underline;
    }
  }

  .clear-filter-link,
  .clear-filter {
    color: var(--accent);
  }

  .clear-filter-link-disabled,
  .disable-clear-filter {
    color: var(--grey-light);
  }

  .mat-button-wrapper {
    .mat-icon.material-icons {
      height: 18px;
      width: 18px;
      font-size: 18px;
      line-height: 16px;
    }
  }

  // SnackBar button color, in Admin UI
  .global-important {
    .mat-button {
      color: var(--accent-coral);
    }
  }

  .mat-checkbox-frame,
  .mat-radio-outer-circle {
    border-color: var(--grey-light);
  }

  // Floorplan uploader
  .project-file-upload-container {
    .properties-table {
      .mat-checkbox-frame {
        border-color: var(--grey-dark);
      }
    }
  }

  .cdk-global-overlay-wrapper {
    $me: &; // reference to parent
    background: rgba(0, 0, 0, 0.2);

    // don't show backdrop for snack bar
    .mat-snack-bar-container {
      @at-root #{$me} {
        background: none;
      }
    }
  }

  .copy-embed-dialog {
    .mat-dialog-container {
      padding: 0;
      height: max-content;
      border-radius: 0;
      top: 50%;
      left: 50%;
      transform: translate(0%, 50%) !important;
    }
  }

  // Model Gallery viewer
  .fileViewer {
    background: transparent;
    height: 100vh;
    overflow: hidden;
    box-shadow: unset;
    padding: 0;
    border-radius: 0;

    .mdc-dialog__surface {
      padding: 0 !important;
      background: transparent;
    }

    .mat-dialog-content {
      margin: 0;
    }

    :not(pre)::-webkit-scrollbar {
      width: 0 !important;
    }

    ::-webkit-scrollbar-thumb:vertical {
      width: 0 !important;
    }
  }
}

.mat-sort-header-arrow {
  color: var(--primary);
}

body {
  skychute-root,
  skychute-embed {
    // overflow: scroll;

    :not(pre)::-webkit-scrollbar:vertical {
      width: 8px;
    }

    :not(pre)::-webkit-scrollbar:horizontal {
      height: 8px;
    }

    ::-webkit-scrollbar-track:vertical {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-button:vertical {
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    /* Turn on single button up on top, and down on bottom */

    ::-webkit-scrollbar-button:start:decrement,
    ::-webkit-scrollbar-button:end:increment {
      display: block;
    }

    /* Turn off the down area up on top, and up area on bottom */

    ::-webkit-scrollbar-button:vertical:start:increment,
    ::-webkit-scrollbar-button:vertical:end:decrement {
      display: none;
    }

    /* Place The scroll down button at the bottom */

    ::-webkit-scrollbar-button:vertical:increment {
      display: none;
    }

    /* Place The scroll up button at the up */

    ::-webkit-scrollbar-button:vertical:decrement {
      display: none;
    }

    ::-webkit-scrollbar-track:vertical {
      background-color: transparent;
      border-radius: 5px;
    }

    /* Track below and above */

    ::-webkit-scrollbar-track-piece:vertical {
      background-color: transparent;
    }

    /* Top area above thumb and below up button */

    ::-webkit-scrollbar-track-piece:vertical:start {
      border-radius: 5px;
    }

    /* Bottom area below thumb and down button */

    ::-webkit-scrollbar-track-piece:vertical:end {
      border-radius: 5px;
    }

    /* The thumb itself */

    ::-webkit-scrollbar-thumb:vertical {
      width: 8px;
      height: 10px;
      background: linear-gradient(45deg, var(--accent-coral), var(--primary));
      border-radius: 10px;
    }

    /* Corner */
    ::-webkit-scrollbar-corner:vertical {
      background-color: var(--accent-coral);
    }
  }
}

.fig-print--show {
  display: none;
}

/*Global css for print*/
@media print {
  .fig-print--hide {
    display: none !important;
  }

  .fig-print--show {
    display: block !important;
  }

  .fig-print--avoid-break {
    page-break-inside: avoid;
  }
}

.mat-simple-snackbar-action {
  color: rgba(255, 255, 255, 0.8);
}

.hide-scroll {
  overflow: hidden;
}

// Global client styles
.client-global__back-button {
  padding: 16px 16px 0 16px;
  @include layout-bp('gt-sm') {
    padding: 24px 40px 0 40px;
  }
}

.client-global__actions {
  background: rgba(255, 255, 255, 0.9);
  min-height: unset;
  margin-bottom: unset;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  right: 0;
  gap: 16px;
  box-shadow: inset 0 1px 1px var(--border-lightest);
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  @include layout-bp('gt-sm') {
    padding: 8px 40px;
    width: calc(100% - 304px);
  }
}

.client-global__right-button {
  width: auto;
}

// Calendar in contract status management - hack required because the more I work with it the more I feel material design is pants
@include layout-bp('xs') {
  .mat-datepicker-dialog {
    max-width: none !important;

    .mat-dialog-container {
      padding: 0;
    }
  }
}

// Mat-paginator overrides for mobile
.mat-paginator-container {
  justify-content: center !important;
  @include layout-bp('gt-sm') {
    justify-content: flex-end !important;
  }
}

// user for displaying a notification icon on menu (courier integration)
.menu-notification-icon {
  position: fixed;
  top: 20px;
  right: 265px;
  z-index: 4;
  display: none;

  @include layout-bp('lt-sm') {
    right: 62px;
  }
}

@include layout-bp('lt-sm') {
  .hide__lt-sm {
    display: none !important;
  }
}

.global__notification--success {
  background: var(--success);
  color: var(--white);

  .mat-button-wrapper {
    color: var(--white);
  }

  // mdc
  .mdc-snackbar__label {
    font-size: 16px !important;
  }

  .mdc-snackbar__surface {
    background: var(--success) !important;
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--white) !important;
  }
}

.global__notification--error {
  background: var(--error);
  color: var(--white);

  .mat-button-wrapper {
    color: var(--white);
  }

  // mdc
  .mdc-snackbar__label {
    font-size: 16px !important;
  }

  .mdc-snackbar__surface {
    background: var(--error) !important;
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--white) !important;
  }
}

.global__notification--warning {
  background: var(--warning);
  color: var(--black);

  .mat-button-wrapper {
    color: var(--black);
  }

  // mdc
  .mdc-snackbar__label {
    font-size: 16px !important;
    color: var(--primary-darker-color) !important;
  }

  .mdc-snackbar__surface {
    background: var(--warning) !important;
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--primary-darker-color) !important;
  }
}

[hidden] {
  visibility: hidden !important;
}

// remove old price list filter black stripes
.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

.cdk-overlay-pane {
  @include layout-bp('lt-sm') {
    max-width: 100% !important;
  }
}

// this is for resolve the conflict with platform style
courier-inbox {
  div[class^="styled__ActionBlock"] {
    a {
      color: white !important;
      background: var(--primary);

      &:hover {
        color: white !important;
        background: var(--primary);
      }
    }
  }
}

// courier inbox class
.jmCfSi {
  padding: 3px 0 !important;
}

// visual separation of the <mat-option> inside <mat-select> for option values which span multiple lines
.mat-mdc-option .mdc-list-item__primary-text {
  padding: 8px 0;
}